<template>
  <v-dialog
    v-model="showSettingsModal"
    max-width="300px"
  >
    <v-card v-if="showSettingsModal">
      <v-row>
        <v-col class="flex-grow-1 col-8 ml-6 mb-3 mt-4 pa-0">
          <v-card-title>
            <div class="darkblue--text subtitle-2 text-uppercase">
              <span>
                Settings
              </span>
            </div>
          </v-card-title>
        </v-col>

        <v-spacer />

        <v-col class="flex-shrink-1 col-3 mt-4">
          <v-btn
            :ripple="false"
            icon
            small
            @click.stop="onClose()"
          >
            <v-icon
              color="brightblue"
            >
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-card-text
        class="mt-6"
      >
        <v-row
          v-if="showThemeSettings"
          class="align-center my-3"
        >
          <v-col>
            <v-row>
              <v-col class="mx-3">
                <v-row class="font-weight-medium">
                  <span>Dark Mode</span>
                </v-row>

                <v-row class="caption">
                  Tailor the app's appearance for you and your environment.
                </v-row>

                <v-row>
                  <v-col class="px-0">
                    <v-switch
                      v-model="currentDarkMode"
                      :label="currentDarkMode ? 'On' : 'Off'"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider />
          </v-col>
        </v-row>

        <v-row
          v-if="showAliasSettings"
          class="align-center my-3"
        >
          <v-col>
            <v-row>
              <v-col class="mx-3">
                <v-row class="font-weight-medium">
                  <span>Alias Mode</span>
                </v-row>

                <v-row
                  v-if="hasAliasPermission"
                  class="caption"
                >
                  View the app as an external user.
                </v-row>

                <v-row
                  v-else
                  class="caption"
                >
                  <v-col class="px-0">
                    <p>This account is aliasing <strong>{{ currentAliasEmail }}</strong>.</p>
                  </v-col>
                </v-row>

                <v-row v-if="hasAliasPermission">
                  <v-col class="px-0 pb-0 col-grow">
                    <v-combobox
                      :items="aliasEmailOptions"
                      label="User Email"
                      :value="currentAliasEmail"
                      flat
                      dense
                      hide-details
                      solo
                      @change="updateAliasEmail"
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-if="hasAliasPermission"
                  class="justify-space-between mb-2"
                >
                  <v-col class="pa-0 col-auto">
                    <v-switch
                      v-model="aliasActiveModel"
                      active-class="primary--text"
                      class="mt-2"
                      hide-details
                      :label="aliasActiveLabel"
                      inset
                    />
                  </v-col>

                  <v-col class="pr-0 col-auto">
                    <v-btn
                      color="primary"
                      small
                      @click.stop="updateAliasMode"
                    >
                      <span>Save</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider />
          </v-col>
        </v-row>

        <demo-mode />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DemoMode from 'src/auth/components/DemoMode'

const { mapGetters: mapAuthGetters, mapState: mapAuthState } = createNamespacedHelpers('auth')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')

export default {
  name: 'SettingsModal',
  components: { DemoMode },
  data() {
    return {
      externals: [],
      showThemeSettings: false,
      selectedAliasEmail: null,
      toggleAliasActive: null,
    }
  },
  computed: {
    ...mapAuthGetters([
      'aliasEmail',
      'hasAliasPermission',
      'inAliasMode',
      'isAliasActive',
      'isDemoWriter',
      'inDemoMode',
      'permissions',
      'selfEmail',
      'settings',
    ]),
    ...mapAuthState({
      demoMode: (state) => state.demoMode,
      didDemoModeFail: (state) => state.didDemoModeFail,
      isLoadingDemoMode: (state) => state.isLoadingDemoMode,
      user: (state) => state.user,
    }),
    ...mapLayoutState({
      darkMode: (state) => state.darkMode,
      isSettingsModalVisible: (state) => state.isSettingsModalVisible,
      themes: (state) => state.themes,
    }),
    showAliasSettings() {
      return this.hasAliasPermission || this.inAliasMode
    },
    currentAliasEmail: {
      get() {
        return this.selectedAliasEmail || this.aliasEmail || null
      },
      set(newValue) {
        this.selectedAliasEmail = newValue
      },
    },
    aliasActiveModel: {
      get() {
        return typeof this.toggleAliasActive === 'boolean' ? this.toggleAliasActive : this.isAliasActive
      },
      set(newValue) {
        this.toggleAliasActive = newValue
      },
    },
    aliasEmailOptions() {
      return this.externals || []
    },
    aliasActiveLabel() {
      return this.aliasActiveModel ? 'On' : 'Off'
    },
    showSettingsModal: {
      get() {
        return this.isSettingsModalVisible
      },
      set(value) {
        this.$store.commit('layout/isSettingsModalVisible', value, { root: true })
      },
    },
    currentDarkMode: {
      get() {
        return this.darkMode
      },
      set(newValue) {
        this.$store.dispatch('layout/setDarkMode', newValue, { root: true })
        this.$vuetify.theme.dark = newValue || false
      },
    },
    currentDemoMode: {
      get() {
        return this.demoMode
      },
      set(newValue) {
        this.inputDemoMode = newValue || false
        this.confirmDemoModeChange = true

        return this.inputDemoMode
      },
    },
    demoModeErrorMessages() {
      return this.errorDemoModeChange ? ['Check for typos.'] : null
    },
    inputDemoModeLabel() {
      const { inputDemoMode, pendingDemoModeChange } = this
      const action = inputDemoMode ? 'On' : 'Off'

      if (pendingDemoModeChange) return `Turning ${action}`

      return action
    },
    inputTest() {
      const action = this.inputDemoMode ? 'on' : 'off'
      return `demo ${action}`
    },
    pendingDemoModeChange() {
      const { confirmDemoModeChange, inputDemoMode, isLoadingDemoMode } = this
      const invalidState = typeof inputDemoMode !== 'boolean'

      if (invalidState) return false

      return confirmDemoModeChange || isLoadingDemoMode
    },
  },
  watch: {
    async showSettingsModal(newValue) {
      if (newValue && this.hasAliasPermission && !this.externals.length) {
        const externals = await this.$store.dispatch('auth/getExternalUsers', null, { root: true })

        this.externals = externals.map((external) => ({
          text: external.docId,
          value: external.docId,
        }))
      }
    },
  },
  created() {
    this.inputDemoMode = this.demoMode
  },
  methods: {
    onClose() {
      this.showSettingsModal = false
      this.cancelDemoModeChange()
    },
    onShow() {
      this.showSettingsModal = true
    },

    updateAliasEmail(selection) {
      this.selectedAliasEmail = selection.value
    },
    updateAliasMode() {
      const { aliasActiveModel, currentAliasEmail } = this
      const active = aliasActiveModel
      const email = currentAliasEmail
      const alias = { active, email }

      this.$store.dispatch('auth/updateAliasMode', alias, { root: true })
    },

    resetErrorDemoModeChange() {
      this.errorDemoModeChange = false
    },
    cancelDemoModeChange() {
      this.inputDemoModeChange = null
      this.errorDemoModeChange = false
      this.confirmDemoModeChange = false

      return false
    },
  },
}
</script>
