<template>
  <v-row
    class="align-center mt-3"
  >
    <v-col>
      <v-row>
        <v-col class="mx-3">
          <v-row class="font-weight-medium">
            <span>Demo Mode</span>
          </v-row>

          <v-row class="caption">
            Share your iAdOps view without sharing sensitive data.
          </v-row>

          <v-row>
            <v-switch
              v-model="currentDemoMode"
              :loading="pendingDemoModeChange"
              :input-value="currentInputDemoMode"
              :value="currentInputDemoMode"
              :disabled="!isDemoWriter"
              :label="inputDemoModeLabel"
              :readonly="currentConfirmDemoModeChange"
              inset
            />
          </v-row>

          <v-row v-if="didDemoModeFail">
            <v-col>
              <v-row>
                An error occurred. Try again or
                <span
                  href="mailto:tech@oao.freshdesk.com"
                  target="_blank"
                >
                  contact us
                </span>.
              </v-row>
            </v-col>
          </v-row>

          <v-row v-else-if="currentConfirmDemoModeChange">
            <v-col>
              <v-row class="caption">
                Type
                <span class="font-weight-medium px-1">{{ inputTest }}</span>
                and press
                <span class="font-weight-medium px-1">Enter</span>
                to confirm your changes.
              </v-row>

              <v-row>
                <v-text-field
                  v-model="currentInputDemoModeChange"
                  :error="currentErrorDemoModeChange"
                  :error-messages="demoModeErrorMessages"
                  :label="inputTest"
                  @keyup.enter="updateDemoMode"
                  @keydown="resetErrorDemoModeChange"
                />
              </v-row>

              <v-row>
                <v-spacer />

                <v-btn
                  color="coolgray"
                  class="mb-3"
                  text
                  x-small
                  @click="cancelDemoModeChange"
                >
                  Cancel
                </v-btn>

                <v-btn
                  color="primary"
                  class="mb-3"
                  text
                  x-small
                  @click="updateDemoMode"
                >
                  Enter
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters, mapState: mapAuthState } = createNamespacedHelpers('auth')

export default {
  name: 'DemoMode',
  computed: {
    ...mapAuthGetters([
      'isDemoWriter',
      'inDemoMode',
    ]),
    ...mapAuthState({
      demoMode: (state) => state.demoMode,
      didDemoModeFail: (state) => state.didDemoModeFail,
      isLoadingDemoMode: (state) => state.isLoadingDemoMode,
      user: (state) => state.user,

      confirmDemoModeChange: (state) => state.confirmDemoModeChange,
      errorDemoModeChange: (state) => state.errorDemoModeChange,
      inputDemoMode: (state) => state.inputDemoMode,
      inputDemoModeChange: (state) => state.inputDemoModeChange,
    }),
    currentConfirmDemoModeChange: {
      get() {
        return this.confirmDemoModeChange
      },
      set(value) {
        this.$store.commit('auth/confirmDemoModeChange', value)
      },
    },
    currentErrorDemoModeChange: {
      get() {
        return this.errorDemoModeChange
      },
      set(value) {
        this.$store.commit('auth/errorDemoModeChange', value)
      },
    },
    currentInputDemoMode: {
      get() {
        return this.inputDemoMode
      },
      set(value) {
        this.$store.commit('auth/inputDemoMode', value)
      },
    },
    currentInputDemoModeChange: {
      get() {
        return this.inputDemoModeChange
      },
      set(value) {
        this.$store.commit('auth/inputDemoModeChange', value)
      },
    },
    currentDemoMode: {
      get() {
        return this.demoMode
      },
      set(newValue) {
        this.currentInputDemoMode = newValue || false
        this.currentConfirmDemoModeChange = true

        return this.currentInputDemoMode
      },
    },
    demoModeErrorMessages() {
      return this.currentErrorDemoModeChange ? ['Check for typos.'] : null
    },
    inputDemoModeLabel() {
      const { currentInputDemoMode, pendingDemoModeChange } = this
      const action = currentInputDemoMode ? 'On' : 'Off'

      if (pendingDemoModeChange) return `Turning ${action}`

      return action
    },
    inputTest() {
      const action = this.currentInputDemoMode ? 'on' : 'off'
      return `demo ${action}`
    },
    pendingDemoModeChange() {
      const { currentConfirmDemoModeChange, currentInputDemoMode, isLoadingDemoMode } = this
      const invalidState = typeof currentInputDemoMode !== 'boolean'

      if (invalidState) return false

      return currentConfirmDemoModeChange || isLoadingDemoMode
    },
  },
  created() {
    this.currentInputDemoMode = this.demoMode
  },
  methods: {
    onClose() {
      this.cancelDemoModeChange()
    },
    resetErrorDemoModeChange() {
      this.currentErrorDemoModeChange = false
    },
    cancelDemoModeChange() {
      this.currentInputDemoModeChange = null
      this.currentErrorDemoModeChange = false
      this.currentConfirmDemoModeChange = false

      return false
    },
    updateDemoMode() {
      const { currentInputDemoMode, currentInputDemoModeChange, inputTest } = this
      const isInputValid = currentInputDemoModeChange === inputTest

      if (isInputValid) {
        // Update user record.
        this.$store.dispatch('auth/updateDemoMode', currentInputDemoMode, { root: true })

        // Update Demo Mode view.
        this.cancelDemoModeChange()
      } else {
        // Show error in Demo Mode view.
        this.currentErrorDemoModeChange = true
      }
    },
  },
}
</script>
